import logo from './images/png/logo.png';
import user from './images/png/user.png';
import usdt from './images/png/tether-usdt-logo.png';
import bnb from './images/png/bnb.png';











const Png = {
      // mobilemenu,
      logo,
      user,
      usdt,
      bnb,
}

export default Png;