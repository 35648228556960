// import slidesmall1 from './images/jpg/slider-small1.jpg';








const Jpg = {
    // slide1,


}

export default Jpg;