import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "./myaccount.css";
import {
  useWeb3ModalAccount,
  useWeb3ModalSigner,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import abi from "../../../../presaleAbi.json";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../component/user/pages/herosection/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const toster = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: 1,
  theme: "light",
};

function MyAccount() {
  const { address, isConnected } = useWeb3ModalAccount();
  const { signer } = useWeb3ModalSigner();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [userTokensData, setUserTokensData] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [totalLockedToken, setTotalLockedToken] = useState(0);
  const [totalUnLockedToken, setTotalUnLockedToken] = useState(0);
  const [totalAvailableToken, setTotalAvailableToken] = useState(0);
  const navigate = useNavigate();
  const handleOpenView = (data, index) => {
    setOpenView(true);
    setSelectedItemData(data);
    setCurrentIndex(index);
  };
  const handleCloseView = () => setOpenView(false);
  const contractAddress = "0xA9B925104342f2C248353cB69EEf538E8D810790";
  const claimToken = async () => {
    const contract = new ethers.Contract(contractAddress, abi, signer);
    try {
      setIsLoading(true);
      const tx = await contract.claimToken(currentIndex.toString());
      await tx.wait();
      setIsLoading(false);
      handleCloseView();
      toast.success("Transaction is successfully completed", {
        toster,
      });
      allReadData();
    } catch (error) {
      setIsLoading(false);
      toast.error("Transaction cancelled", {
        toster,
      });
    }
  };

  const claimAllToken = async () => {
    const contract = new ethers.Contract(contractAddress, abi, signer);
    try {
      setShowLoader(true);
      const tx = await contract.claimAllAvailableTokens();
      await tx.wait();
      setShowLoader(false);
      allReadData();
      toast.success("Transaction is successfully completed", {
        toster,
      });
    } catch (error) {
      setShowLoader(false);
      toast.error("Transaction cancelled", {
        toster,
      });
    }
  };

  const allReadData = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://data-seed-prebsc-1-s1.binance.org:8545/"
    );
    const contract = new ethers.Contract(contractAddress, abi, provider);
    try {
      const getPhaseDetails = await contract.getPrivateSaleBuyerInfo(address);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      let totalPLT = 0;
      let lockPLT = 0;
      let availablePLT = 0;
      const userData = getPhaseDetails.map((element) => {
        const getTime = (time) => {
          const timestamp = time;
          const date = new Date(timestamp * 1000);
          const year = date.getFullYear().toString().slice(-2);
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          const formattedDate = `${day}/${month}/${year}`;
          return formattedDate;
        };
        totalPLT = totalPLT + Number(element[2].toString() / 10 ** 18);
        lockPLT = lockPLT + Number(element[7].toString() / 10 ** 18);
        if (
          (currentTimestamp > element[4].toNumber()) &
          (element[6] === true)
        ) {
          availablePLT =
            availablePLT + Number(element[2].toString() / 10 ** 18) / 2;
        }
        if (
          (currentTimestamp > element[5].toNumber()) &
          (element[3] === true)
        ) {
          availablePLT =
            availablePLT + Number(element[2].toString() / 10 ** 18) / 2;
        }
        const data = {
          USDTToken: element[1].toString() / 10 ** 18,
          PLTToken: element[2].toString() / 10 ** 18,
          yearVestingTime: getTime(element[4].toNumber()),
          totalVestingTime: getTime(element[5].toNumber()),
          active: element[3],
          firstYearClaim: element[6],
          remainingToken: element[7].toString() / 10 ** 18,
          startTime: getTime(element[8].toNumber()),
          yearTimeStamp: element[4].toNumber(),
          totalTimeStamp: element[5].toNumber(),
        };
        return data;
      });
      setTotalUnLockedToken(totalPLT - lockPLT);
      setTotalLockedToken(lockPLT);
      setUserTokensData(userData);
      setTotalAvailableToken(availablePLT);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    allReadData();
  }, [address]);

  const data = () => {
    setTimeout(() => {
      if (!isConnected) {
        navigate("/");
      }
    }, 1000);
  };

  useEffect(() => {
    data();
  }, [isConnected]);

  return (
    <>
      <Index.Box className="my-account-wrapper">
        <Index.Box className="container">
          <Index.Box className="my-account-sec">
            <Index.Box className="back-btn-sec">
              <Index.Link to="/" className="back-btn">
                <img src={Index.Svg.back} alt="back" className="back-ic" />
                Back
              </Index.Link>
            </Index.Box>
            <Index.Box className="main-title">
              <Index.Typography
                component="p"
                variant="p"
                className="title-text"
              >
                My Account
              </Index.Typography>
            </Index.Box>
            <Index.Box className="plt-card-sec">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 4",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="plt-card space-between">
                      <Index.Box className="plt-card-left">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="card-value"
                        >
                          {totalLockedToken.toFixed(2)}
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="card-title"
                        >
                          Locked PLT
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="plt-card-right">
                        <Index.Box className="round-bg cus-center">
                          <img
                            src={Index.Svg.plutopeIc}
                            alt="plutopeIc"
                            className="plutope-ic"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 4",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="plt-card space-between">
                      <Index.Box className="plt-card-left">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="card-value"
                        >
                          {totalUnLockedToken.toFixed(2)}
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="card-title"
                        >
                          UnLocked PLT
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="plt-card-right">
                        <Index.Box className="round-bg cus-center">
                          <img
                            src={Index.Svg.plutopeIc}
                            alt="plutopeIc"
                            className="plutope-ic"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 4",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="plt-card space-between">
                      <Index.Box className="plt-card-left">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="card-value"
                        >
                          {totalAvailableToken.toFixed(2)}
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="card-title"
                        >
                          Available PLT
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="plt-card-right">
                        {showLoader ? (
                          <Index.Box style={{ "margin-right": "10px" }}>
                            <Loader />
                          </Index.Box>
                        ) : (
                          <Index.Button
                            className="claim-btn"
                            onClick={claimAllToken}
                          >
                            Claim
                          </Index.Button>
                        )}
                        <Index.Box className="round-bg cus-center">
                          <img
                            src={Index.Svg.plutopeIc}
                            alt="plutopeIc"
                            className="plutope-ic"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="usdt-table-sec">
              <Index.Box className="offering-cus-table">
                <Index.Box className="offering-table-head">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th cus-table-th"
                  >
                    USDT
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th cus-table-th"
                  >
                    PLT
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th cus-table-th"
                  >
                    Date & Time
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th cus-table-th"
                  >
                    Details
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="offering-table-body">
                  {userTokensData.map((data, index) => (
                    <Index.Box className="offering-tr gradient-box">
                      <Index.Box className="offering-tr-main space-between gradient-inner-box">
                        <Index.Box className="offering-list d-flex w-100">
                          <Index.Box className="w-25">
                            <Index.Box className="offering-td-box quantity-box gradient-box">
                              <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="offering-th-text"
                                >
                                  {Number(data.USDTToken).toFixed(2)}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="w-25">
                            <Index.Box className="offering-td-box quantity-box gradient-box cus-table-th">
                              <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="offering-th-text"
                                >
                                  {Number(data.PLTToken).toFixed(2)}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="w-25">
                            <Index.Box className="offering-td-box quantity-box gradient-box cus-table-th">
                              <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="offering-th-text"
                                >
                                  {data.startTime}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="w-25">
                            <Index.Box className="view-btn-sec">
                              <Index.PrimaryButton
                                btnLabel="view"
                                className="primary-btn view-btn"
                                onClick={() => handleOpenView(data, index)}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  ))}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="cus-nodal-ul">
            <Index.Box className="cus-modal-li space-between">
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-title"
              >
                USDT
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-value"
              >
                {selectedItemData
                  ? Number(selectedItemData.USDTToken).toFixed(2)
                  : 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="cus-modal-li space-between">
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-title"
              >
                PLT
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-value"
              >
                {selectedItemData
                  ? Number(selectedItemData.PLTToken).toFixed(2)
                  : 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="cus-modal-li space-between">
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-title"
              >
                Vesting Declaration
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-value"
              >
                {selectedItemData ? selectedItemData.startTime : 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="cus-modal-li space-between">
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-title"
              >
                Locking Period
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-value"
              >
                2 Years
              </Index.Typography>
            </Index.Box>
            <Index.Box className="cus-modal-li space-between">
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-title"
              >
                50% Release Date
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-value"
              >
                {selectedItemData ? selectedItemData.yearVestingTime : 0}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="cus-modal-li space-between">
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-title"
              >
                Vesting Ends
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="li-list-value"
              >
                {selectedItemData ? selectedItemData.totalVestingTime : 0}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-btn-sec space-between">
            <Index.BorderButton
              btnLabel="Close"
              className="border-btn modal-btn"
              onClick={handleCloseView}
            />
            {isLoading ? (
              <Index.Box
                style={{ "margin-right": "13px", "margin-left": "122px" }}
              >
                <Loader />
              </Index.Box>
            ) : (Math.floor(Date.now() / 1000) >
                selectedItemData?.totalTimeStamp &&
                selectedItemData?.active === true) ||
              (Math.floor(Date.now() / 1000) >
                selectedItemData?.yearTimeStamp &&
                selectedItemData?.firstYearClaim === true) ? (
              <>
                <Index.PrimaryButton
                  btnLabel="Claim"
                  className="primary-btn modal-btn"
                  firstYearClaim
                  onClick={claimToken}
                />
              </>
            ) : (
              <></>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

export default MyAccount;
