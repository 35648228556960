import Routes from './routes/Routes'
import './assets/style/global.css';

import './component/user/defaulLayout/defaulLayout.css'
import './component/user/pages/herosection/herosection.css'
import './component/user/pages/herosection/herosection.responsive.css'

import './component/user/defaulLayout/defaulLayout.responsive.css'

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

import './assets/style/global.responsive.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import './assets/style/swiper.css';
import './assets/style/responsive.css';

// 1. Get projectId
const projectId = '2d7ded4b2b1becfe9064b344d8c4ba63'

// 2. Set chains
const Goerli = {
  chainId: 5,
  name: 'Goerli',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://goerli.infura.io/v3/c699d17f7200444c8ffa9c281ed20452'
}

const BSCTestnet = {
  chainId: 97,
  name: 'BNB Testnet',
  currency: 'BNB',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
}

const Mumbai = {
  chainId: 80001,
  name: 'Mumbai',
  currency: 'MATIC',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://polygon-mumbai.infura.io/v3/c699d17f7200444c8ffa9c281ed20452'
}

// 3. Create modal
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com',
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [Goerli,BSCTestnet,Mumbai],
  projectId
})
function App() {

  return (
    
    <div className="App">
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
     <Routes/>
    </div>
  );
}

export default App;
