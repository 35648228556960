import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalSigner,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import abi from "../../../../presaleAbi.json";
import tokenAbi from "../../../../tokenAbi.json";
import { toast } from "react-toastify";
import Loader from "./Loader";
const toster = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: 1,
  theme: "colored",
};

export default function HeroSection() {
  const [selectedToken, setSelectedToken] = React.useState("USDT");
  const [tokenInBNB, setTokenInBNB] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [tokensData, setTokensData] = useState({
    currentPhasePrice: 0,
    nextPhasePrice: 0,
    tokenSold: 0,
    totalTokenSoldInPhases: 0,
    privateSalePhaseName: "",
    activePhase: "0",
    presaleIsActive: false,
  });
  const [tokonomicsData, setTokonomicsData] = useState([]);
  const [getToken, setGetToken] = useState(0);

  const { open } = useWeb3Modal();
  const { isConnected, address } = useWeb3ModalAccount();
  const { signer } = useWeb3ModalSigner();
  const contractAddress = "0xA9B925104342f2C248353cB69EEf538E8D810790";

  const allReadData = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://data-seed-prebsc-1-s1.binance.org:8545/"
    );
    const contract = new ethers.Contract(contractAddress, abi, provider);
    try {
      const getPhaseDetails = await contract.getPhasePricesAndTokens();

      setTokensData({
        currentPhasePrice: getPhaseDetails[0].toString() / 10000,
        nextPhasePrice: getPhaseDetails[1].toString() / 10000,
        tokenSold: getPhaseDetails[2].toString() / 10 ** 18,
        totalTokenSoldInPhases: getPhaseDetails[3].toString() / 10 ** 18,
        privateSalePhaseName: getPhaseDetails[4],
        activePhase: getPhaseDetails[5].toString(),
        presaleIsActive: getPhaseDetails[6],
      });

      const getTokonomicsDetails = await contract.getTokonomicsData();
      const price = await contract.getPhasePrice();
      const optimizedData = getTokonomicsDetails
        .slice(0, 3)
        .map((element, index) => {
          const totalRaise =
            (element[2].toString() / 10 ** 18) *
            (price[index]?.toNumber() || 0);
          return {
            OfferingType: element[0],
            Quantity: element[2].toString() / 10 ** 18,
            Price: (price[index]?.toNumber() || 0) / 10000,
            Raise: totalRaise / 10000,
          };
        });
      setTokonomicsData(optimizedData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    allReadData();
  }, []);


  const buyTokens = async () => {
    const tokenContract = new ethers.Contract(
      "0x81aAe972B3b36B7E628D9F942544e01af6977CE7",
      tokenAbi,
      signer
    );
    const contract = new ethers.Contract(contractAddress, abi, signer);
    try {
      setIsLoading(true);
      const weiValue = ethers.utils.parseEther(getToken.toString());
      if (selectedToken === "USDT") {
        const checkAllowence = await tokenContract.allowance(
          address,
          contractAddress
        );
        if (Number(checkAllowence.toString()) < Number(weiValue.toString())) {
          const approveToken = await tokenContract.approve(
            contractAddress,
            weiValue
          );
          await approveToken.wait();
        }
        const tx = await contract.buyPrivateSaleToken("0", weiValue);
        await tx.wait();
        setIsLoading(false);
        toast.success("Transaction is successfully completed", {
          toster,
        });
        allReadData();
      } else {
        setIsLoading(true);
        const tx = await contract.buyPrivateSaleToken("1", weiValue, {
          value: weiValue,
        });
        await tx.wait();
        setIsLoading(false);
        toast.success("Transaction is successfully completed", {
          toster,
        });
        allReadData();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Transaction cancelled", {
        toster,
      });
    }
  };

  const handleChangeInput = (e) => {
    const value = e.target.value;
    setGetToken(value);
    const provider = new ethers.providers.JsonRpcProvider(
      "https://data-seed-prebsc-1-s1.binance.org:8545/"
    );
    const contract = new ethers.Contract(contractAddress, abi, provider);
    try {
      const weiValue = ethers.utils.parseEther(value.toString());
      contract
        .getBNBEquivalentUSDT(weiValue)
        .then((result) => {
          const getTokenPrice = parseInt(
            result / tokensData.currentPhasePrice / 10 ** 18
          ).toFixed();
          setTokenInBNB(getTokenPrice);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Index.Box className="hero-section-wrapper">
        <Index.Box className="container">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 6",
                }}
                className="grid-column res-grid-column"
              >
                <Index.Box className="hero-left-main">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="gradient-text"
                  >
                    Lorem ipsum dolor sit amet, co <br></br>
                    tur adipiscing elit
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="platform-text"
                  >
                    All-in-One AI <br></br>
                    Platform
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="res-cus-table">
                  <Index.Box className="offering-cus-table">
                    <Index.Box className="offering-table-head">
                      {/* <Index.Typography component="p" variant='p' className='offering-th'></Index.Typography> */}
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th th-offe-type"
                      >
                        Offering Type
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th th-quantity"
                      >
                        Quantity
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th th-quantity th-offe-price"
                      >
                        Price
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th th-quantity th-offe-raise"
                      >
                        Raise
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="offering-table-body">
                      {tokonomicsData.map((data, index) => (
                        <Index.Box className="offering-tr gradient-box">
                          <Index.Box className="offering-tr-main space-between gradient-inner-box">
                            {index <= tokensData.activePhase &&
                            tokensData.presaleIsActive === true ? (
                              <Index.Box className="lock-main gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <img
                                    src={Index.Svg.unlock}
                                    alt="lock"
                                    className="lock-ic"
                                    style={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.presaleIsActive === true
                                          ? "lightgreen !important"
                                          : "white",
                                    }}
                                  />
                                </Index.Box>
                              </Index.Box>
                            ) : 2 === parseInt(tokensData.activePhase) &&
                            tokensData.presaleIsActive === false ? (
                              <Index.Box className="th-blank">
                                <Index.Box className="lock-main gradient-box">
                                  <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                    <img
                                      src={Index.Svg.unlock}
                                      alt="lock"
                                      className="lock-ic"
                                      style={{
                                        color:
                                          data.OfferingType ===
                                            tokensData.privateSalePhaseName &&
                                          tokensData.presaleIsActive === true
                                            ? "lightgreen !important"
                                            : "white",
                                      }}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            ): (
                              <Index.Box className="lock-main gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <img
                                    src={Index.Svg.padlock}
                                    alt="lock"
                                    className="lock-ic"
                                    style={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.presaleIsActive === true
                                          ? "lightgreen !important"
                                          : "white",
                                    }}
                                  />
                                </Index.Box>
                              </Index.Box>
                            )}
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text offering-pre-seed"
                              sx={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "#18BF2E !important "
                                    : "white",
                              }}
                            >
                              {data.OfferingType}
                            </Index.Typography>
                            <Index.Box className="offering-list d-flex">
                              <Index.Box className="offering-td-box quantity-box gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="offering-th-text"
                                    sx={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.presaleIsActive === true
                                          ? "#18BF2E !important "
                                          : "white",
                                    }}
                                  >
                                    {Number(data.Quantity).toFixed(2)}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="offering-td-box quantity-box gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="offering-th-text"
                                    sx={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.presaleIsActive === true
                                          ? "#18BF2E !important "
                                          : "white",
                                    }}
                                  >
                                    ${data.Price}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="offering-td-box quantity-box gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="offering-th-text"
                                    sx={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.presaleIsActive === true
                                          ? "#18BF2E !important "
                                          : "white",
                                    }}
                                  >
                                    ${Number(data.Raise).toFixed(2)}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      ))}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="seed-wrapper">
                  <Index.Box className="seed-main">
                    <Index.Box className="seed-main-before"></Index.Box>
                    <Index.Box className="position-relative">
                      <Index.Box className="seed-head-sec space-between">
                        <Index.Box className="seed-title-sec cus-center">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="seed-title-text"
                          >
                            {tokensData.privateSalePhaseName}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="open-sec cus-center">
                          <Index.Box className="green-dot"></Index.Box>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="open-text"
                          >
                            Open
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="seed-progress-wrapper">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="seed-progress-title common-para"
                        >
                          Until next price
                          <span>${tokensData.nextPhasePrice}</span>
                        </Index.Typography>
                        <Index.Box className="seed-progressbar-main">
                          <Index.Box className="seed-progressbar-inner"></Index.Box>
                        </Index.Box>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="seed-progress-title common-para"
                        >
                          <span>{tokensData.tokenSold.toFixed()} PLT</span> /
                          <span>{tokensData.totalTokenSoldInPhases} PLT</span>
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="seed-price-wrapper cus-center">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="seed-price-text common-para"
                        >
                          {tokensData.privateSalePhaseName} Price
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="seed-price-value"
                        >
                          {tokensData.currentPhasePrice}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="seed-usdt-sec">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="seed-usdt-text"
                        >
                          1 PLT = ${tokensData.currentPhasePrice}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="bsc-sec cus-center">
                        <img src={Index.Svg.bsc} alt="bsc" className="bsc-ic" />
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="seed-bsc-text common-para"
                        >
                          Buy with BSC
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="swap-sec">
                        <Index.Box className="bsc-sec space-between">
                          <Index.Box className="swap-sec-left ">
                            <Index.Box className="converter-wrape">
                              <Index.Select
                                value={selectedToken}
                                label="Age"
                                onChange={(e) =>
                                  setSelectedToken(e.target.value)
                                }
                              >
                                <Index.MenuItem value={"USDT"}>
                                  <img
                                    src={Index.Png.usdt}
                                    alt="bsc"
                                    className="dd-icon"
                                  />{" "}
                                  USDT
                                </Index.MenuItem>
                                <Index.MenuItem value={"BNB"}>
                                  <img
                                    src={Index.Png.bnb}
                                    alt="bsc"
                                    className="dd-icon"
                                  />{" "}
                                  BNB
                                </Index.MenuItem>
                              </Index.Select>
                            </Index.Box>

                            {/* <Index.Typography
                              component="p"
                              variant="p"
                              className="dollar-text"
                            >
                              $
                            </Index.Typography> */}

                            <Index.Box className="round-bg cus-center">
                              <img
                                src={Index.Svg.bsc}
                                alt="bsc"
                                className="bsc-ic"
                              />
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="form-group home-input-main">
                            <Index.TextField
                              className="form-control"
                              placeholder="0"
                              onChange={handleChangeInput}
                            ></Index.TextField>
                          </Index.Box>
                          <Index.Box className="swap-sec-left ">
                            <Index.Box className="round-bg cus-center">
                              <img
                                src={Index.Svg.plutopeIc}
                                alt="plutopeIc"
                                className="plutope-ic"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="gradient-text"
                        >
                          {selectedToken === "USDT"
                            ? getToken === 0
                              ? 0
                              : parseInt(
                                  getToken / tokensData.currentPhasePrice
                                ).toFixed()
                            : getToken === 0
                            ? 0
                            : tokenInBNB}
                        </Index.Typography>
                      </Index.Box>
                      {isLoading ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Loader />
                        </div>
                      ) : isConnected ? (
                        <Index.PrimaryButton
                          onClick={buyTokens}
                          btnLabel="Buy Token"
                          className="primary-btn"
                        />
                      ) : (
                        <Index.PrimaryButton
                          onClick={() => open()}
                          btnLabel="Connect Wallet"
                          className="primary-btn"
                        />
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
