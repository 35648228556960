// import logo from './images/svg/logo.svg';
import customer from './images/svg/customer.svg';
import instagram from './images/svg/instagram.svg';
import facebook from './images/svg/facebook.svg';
import twitter from './images/svg/twitter.svg';
import youtube from './images/svg/youtube.svg';
import padlock from './images/svg/padlock.svg';
import bsc from './images/svg/bsc.svg';
import plutopeIc from './images/svg/plutopeIc.svg';
import back from './images/svg/back.svg';
import unlock from './images/svg/unlock.svg';
import burgerMenu from './images/svg/burgerMenu.svg';
import close from './images/svg/close.svg';








const Svg = {
    // logo,
    customer,
    instagram,
    facebook,
    twitter,
    youtube,
    padlock,
    bsc,
    plutopeIc,
    back,
    unlock,
    burgerMenu,
    close,
}

export default Svg;