import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { useNavigate } from "react-router-dom";
import { useDisconnect, useWeb3ModalAccount } from "@web3modal/ethers5/react";

export default function Header() {
  const [isActive, setIsActive] = useState(false);
  const [profileActive, setProfileActive] = useState(false);
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();
  const profileClick = () => {
    setProfileActive(!profileActive);
  };
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
    document.body.classList[isActive ? "remove" : "add"]("body-overflow");
  };

  // useEffect(() => {
  //   if (!isConnected) {
  //     navigate('/');
  //   }
  // }, [isConnected,]);

  return (
    <>
      <Index.Box className="header-main">
        <Index.Box className="container">
          <Index.Box className="header-row">
            <Index.Box className="header-cust-col">
              <Index.Box className="header-logo-box">
                <img
                  src={PagesIndex.Png.logo}
                  className="header-logo"
                  alt="logo"
                />
              </Index.Box>
            </Index.Box>
            <Index.Box className="header-cust-col">
              <Index.Box
                className={`header-nav-main ${
                  isActive ? "mobilemenu-active" : ""
                }`}
              >
                <img
                  src={PagesIndex.Svg.close}
                  className="close-ic"
                  alt="close"
                />

                <Index.List className="header-nav-ul">
                  <Index.ListItem className="header-nav-li res-header-nav-li">
                    <Index.Link className="header-nav-link">
                      <img
                        src={PagesIndex.Png.logo}
                        className="res-header-logo"
                        alt="logo"
                      />
                    </Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className="header-nav-li">
                    <Index.Link className="header-nav-link active">
                      Home
                    </Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className="header-nav-li">
                    <Index.Link className="header-nav-link">
                      How to Buy
                    </Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className="header-nav-li">
                    <Index.Link className="header-nav-link">
                      Whitepaper
                    </Index.Link>
                  </Index.ListItem>

                  <w3m-button />
                </Index.List>
              </Index.Box>
            </Index.Box>
            <Index.Box className="header-cust-col">
              <Index.Box className="header-right-main">
                <Index.Link className="header-social-link">
                  <img
                    src={PagesIndex.Svg.instagram}
                    className="header-social-icon"
                    alt="instagram"
                  />
                </Index.Link>
                <Index.Link className="header-social-link">
                  <img
                    src={PagesIndex.Svg.facebook}
                    className="header-social-icon"
                    alt="facebook"
                  />
                </Index.Link>
                <Index.Link className="header-social-link">
                  <img
                    src={PagesIndex.Svg.twitter}
                    className="header-social-icon"
                    alt="twitter"
                  />
                </Index.Link>
                {/* <Index.Link className='header-social-link'><img src={PagesIndex.Svg.youtube} className='header-social-icon' /></Index.Link> */}
                {address ? (
                  <>
                    <Index.Link
                      className="header-social-link"
                      onClick={profileClick}
                    >
                      <img
                        src={PagesIndex.Png.user}
                        className="header-social-icon cus-filter"
                        alt="user"
                      />
                    </Index.Link>
                    <Index.Box
                      className={
                        profileActive
                          ? "user-dropdown"
                          : "user-dropdown user-close"
                      }
                    >
                      <Index.Box className="user-li">
                        <Index.Link
                          className="user-drop-link"
                          to={"/my-account"}
                          onClick={profileClick}
                        >
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="user-li-text"
                          >
                            My Account
                          </Index.Typography>
                        </Index.Link>
                      </Index.Box>
                      <Index.Box className="user-li">
                        <Index.Link
                          className="user-drop-link"
                          onClick={() => {
                            disconnect();
                            profileClick();
                          }}
                        >
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="user-li-text"
                          >
                            Disconnect
                          </Index.Typography>
                        </Index.Link>
                      </Index.Box>
                    </Index.Box>
                  </>
                ) : (
                  <></>
                )}

                {/* <Index.Link className='header-social-link'><svg className='header-social-icon' xmlns="http://www.w3.org/2000/svg" width="14.604" height="14.601" viewBox="0 0 14.604 14.601">
                  <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M7.3,5.795a3.744,3.744,0,1,0,3.744,3.744A3.738,3.738,0,0,0,7.3,5.795Zm0,6.177A2.434,2.434,0,1,1,9.732,9.538,2.438,2.438,0,0,1,7.3,11.972Zm4.77-6.33a.873.873,0,1,1-.873-.873A.871.871,0,0,1,12.068,5.642Zm2.479.886a4.321,4.321,0,0,0-1.179-3.059,4.349,4.349,0,0,0-3.059-1.179c-1.205-.068-4.819-.068-6.024,0A4.343,4.343,0,0,0,1.225,3.465,4.335,4.335,0,0,0,.046,6.525c-.068,1.205-.068,4.819,0,6.024a4.321,4.321,0,0,0,1.179,3.059,4.355,4.355,0,0,0,3.059,1.179c1.205.068,4.819.068,6.024,0a4.321,4.321,0,0,0,3.059-1.179,4.349,4.349,0,0,0,1.179-3.059c.068-1.205.068-4.815,0-6.021ZM12.99,13.842A2.464,2.464,0,0,1,11.6,15.23a16.092,16.092,0,0,1-4.3.293,16.217,16.217,0,0,1-4.3-.293,2.464,2.464,0,0,1-1.388-1.388,16.092,16.092,0,0,1-.293-4.3,16.217,16.217,0,0,1,.293-4.3A2.464,2.464,0,0,1,2.995,3.846a16.092,16.092,0,0,1,4.3-.293,16.217,16.217,0,0,1,4.3.293A2.464,2.464,0,0,1,12.99,5.234a16.092,16.092,0,0,1,.293,4.3A16.082,16.082,0,0,1,12.99,13.842Z" transform="translate(0.005 -2.238)" fill="#fff" />
                </svg></Index.Link> */}
              </Index.Box>
              <img
                src={PagesIndex.Svg.burgerMenu}
                alt="burgerMenu"
                className="burger-menu-icon"
                onClick={handleClick}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
