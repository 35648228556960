import React from 'react'
import Index from '../../../Index'

function BorderButton(props) {
  return (
    <>
      <Index.Box className='border-btn-main'>
        <Index.Button className={props.className} onClick={props.onClick}>{props.btnLabel}</Index.Button>
      </Index.Box>
    </>
  )
}

export default BorderButton
