import React from 'react'
import Index from '../../Index'

export default function PurpleButton(props) {
  return (
      <>
      <Index.Box className='purple-btn-main'>
        <Index.Button className={props.className} onClick={props.onClick}>{props.btnLabel}</Index.Button>
      </Index.Box>


      {/* use this button like below demo */}
      {/* <Index.PurpleButton btnLabel="View Button" className="purple-btn"/> */}
    </>
  )
}
