import React, { useEffect, useState } from 'react'
import PagesIndex from '../../PagesIndex';
import Index from '../../Index';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
            background: "#0c2f50",
            color: "rgba(255, 255, 255, 1)",
            fontSize: "11px",
            fontFamily: "Poppins-Regular",
            padding: "7px",
            lineHeight: "19px",
            border: "1px solid #ffffff1a",
      },
}));


export default function Sidebar(props) {

      const location = PagesIndex.useLocation();


      const navigate = PagesIndex.useNavigate()


      const [open, setOpen] = React.useState(true);
      const [screen, setScreen] = useState('');
      const handleClickAccount = () => {
            setOpen(!open);
      };

      useEffect(() => {
            console.log(window.screen.width, 226)
            if (window.screen.width < 768) {
                  setScreen('Mobile')
            } else {
                  setScreen('Desktop')
            }
      }, [window.screen.availHeight])

      return (
            <>

                  <Index.Box className={`admin-sidebar-main ${(screen == 'Mobile' ? !props.open : props.open) ? "active" : ""}`}>
                        <Index.Box className="admin-sidebar-inner-main">
                              <Index.Box className="admin-sidebar-logo-main">
                                    <img src={PagesIndex.Png.logo} className="admin-sidebar-logo" alt='logo' />
                                    <Index.Button onClick={() => {
                                          props.setOpen(!props.open)
                                          document.body.classList[props.open ? "add" : "remove"](
                                                "body-overflow"
                                          );
                                    }} className="sidebar-close-btn">
                                          <img src={PagesIndex.Svg.close} className="close-icon" alt='logo' />
                                    </Index.Button>
                              </Index.Box>

                              <Index.Box className="admin-sidebar-list-main">
                                    <Index.List className="admin-sidebar-list">
                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right" arrow>
                                                      <Index.Link to='/admin/dashboard' 
                                                            className={
                                                                  location?.pathname.includes("/admin/dashboard")
                                                                        ? "admin-sidebar-link active"
                                                                        : "admin-sidebar-link"
                                                            }>
                                                            <img src={PagesIndex.Svg.dashboard} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/toggle-color-mode'   className={
                                                                  location?.pathname.includes("/admin/toggle-color-mode")
                                                                        ? "admin-sidebar-link active"
                                                                        : "admin-sidebar-link"
                                                            }> 
                                                            <img src={PagesIndex.Svg.userlist} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>


                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/ck-editor-demo' className="admin-sidebar-link" onClick={() => {
                                                navigate("/user/upload-file")
                                          }}>
                                                            <img src={PagesIndex.Svg.usercard} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/dashboard' className="admin-sidebar-link">
                                                            <img src={PagesIndex.Svg.editpage} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/dashboard' className="admin-sidebar-link">
                                                            <img src={PagesIndex.Svg.dashboard} alt='sidebar icon' className='admin-sidebar-icons' />
                                                              <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/dashboard' className="admin-sidebar-link">
                                                            <img src={PagesIndex.Svg.userlist} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/dashboard' className="admin-sidebar-link">
                                                            <img src={PagesIndex.Svg.usercard} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/dashboard' className="admin-sidebar-link">
                                                            <img src={PagesIndex.Svg.editpage} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/dashboard' className="admin-sidebar-link">
                                                            <img src={PagesIndex.Svg.dashboard} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/dashboard' className="admin-sidebar-link">
                                                            <img src={PagesIndex.Svg.userlist} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/dashboard' className="admin-sidebar-link">
                                                            <img src={PagesIndex.Svg.usercard} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <LightTooltip title="Dashboard" placement="right">
                                                      <Index.Link to='/admin/dashboard' className="admin-sidebar-link">
                                                            <img src={PagesIndex.Svg.editpage} alt='sidebar icon' className='admin-sidebar-icons' />
                                                            <span className='sidebar-span-link'>Dashboard</span>
                                                      </Index.Link>
                                                </LightTooltip>
                                          </Index.ListItem>

                                          {/* <Index.ListItem className='admin-sidebar-listitem admin-submenu-listitem-main' onClick={handleClickAccount}>
                                                <Index.Link className='admin-sidebar-link'>
                                                      <img src={PagesIndex.Svg.changepassword} alt='sidebar icon' className='admin-sidebar-icons' />   CMS
                                                      {open ? <Index.ExpandMore className='expandmore-icon' /> : <Index.ExpandLess className='expandless-icon' />}
                                                      <Index.Box className='submenu-main'>
                                                            <Index.Collapse in={open} timeout="auto" unmountOnExit className='submenu-collapse'>
                                                                  <Index.List component="div" disablePadding className='admin-sidebar-submenulist'>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Link className='admin-sidebar-link'>
                                                                                    About
                                                                              </Index.Link>
                                                                        </Index.ListItem>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Link className='admin-sidebar-link '>
                                                                                    Terms & Conditions
                                                                              </Index.Link>
                                                                        </Index.ListItem>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Link className='admin-sidebar-link '>
                                                                                    Privacy Policy
                                                                              </Index.Link>
                                                                        </Index.ListItem>
                                                                  </Index.List>
                                                            </Index.Collapse>
                                                      </Index.Box>
                                                </Index.Link>
                                          </Index.ListItem> */}
                                    </Index.List>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
