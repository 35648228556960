import React from 'react'
import Index from '../../../Index'


export default function Home() {
  return (
    <>
      <Index.Box className="home-hero-main">
        <Index.HeroSection />
      </Index.Box>
    </>
  )
}
