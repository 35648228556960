import React from 'react'
import { Outlet } from 'react-router-dom'
import Index from '../../../../component/Index';

const Layout = () => {
  return (
    <>
    <Index.Box className="overlay-section mobile-show"></Index.Box>
      <Index.Header />
      <Outlet />
      {/* <Index.Footer /> */}
    </>
  )
}

export default Layout;