
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Sidebar from "./admin/defaulLayout/Sidebar";
import Header from "./admin/defaulLayout/Header";
import PrimaryButton from '../component/common/Button/primarybutton/PrimaryButton';
import AuthFooter from "./admin/defaulLayout/AuthFooter";
import AuthBackground from "./admin/defaulLayout/AuthBackground";
import { useLocation, useNavigate } from "react-router-dom";

export default {
      Svg,
      Png,
      Sidebar,
      Header,
      PrimaryButton,
      AuthFooter,
      AuthBackground,
      useLocation,
      useNavigate,
};
